<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Asset Management Systems</h2>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li>Asset Management Systems</li>
            </ul>
        </div>
    </div>
</div>