import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oracle-cloude-application',
  templateUrl: './oracle-cloude-application.component.html',
  styleUrls: ['./oracle-cloude-application.component.scss']
})
export class OracleCloudeApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
