<!-- Start Page Title Area -->
<!-- <app-header></app-header> -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Enterprise Resource Planning(ERP)</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Projects Details</li>
            </ul>
        </div>
    </div>

    <!-- <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div> -->
</div>
<!-- End Page Title Area -->

<!-- Start Projects Details Area -->
<section class="projects-details-area ptb-110"  #projectsDetails>
    <div class="container">
        <div class="projects-details">
            <div class="pb-4">
                <ul class="d-flex list-unstyled justify-content-center flex-wrap">
                    <li class="border-right px-4 py-1">
                        <a  routerLink="/project-details" fragment="sec1"><h5>Business Process Re-engineering</h5></a>
                    </li>
                    <li class="border-right px-4 py-1 ">
                        <a routerLink="/project-details" fragment="sec2"><h5>Robotic Process Automation</h5></a>
                    </li>
                    <li class="border-right px-4 py-1">
                        <a routerLink="/project-details" fragment="sec3"><h5>Center of Excellence for DevOps</h5></a>
                    </li>
                    <li class="border-right px-4 py-1">
                        <a routerLink="/project-details" fragment="sec4"><h5>Center of Excellence For Quality Assurance</h5></a>
                    </li>
                    <li class="border-right px-4 py-1">
                        <a routerLink="/project-details" fragment="sec5"><h5>Training</h5></a>
                    </li>
                    <li class="px-4 py-1">
                        <a routerLink="/project-details" fragment="sec6"><h5>Reporting</h5></a>
                    </li>
                </ul>
            </div>
            <div class="projects-image-slides owl-carousel owl-theme">
                <div class="single-image"> 
                    <img src="assets/img/projects/projects1.jpg" alt="image">
                </div>
 
                <div class="single-image">
                    <img src="assets/img/projects/projects2.jpg" alt="image">
                </div> 

                <div class="single-image">
                    <img src="assets/img/projects/projects3.jpg" alt="image">
                </div>
            </div>
 
            <div class="projects-details-desc">
                <div class="mx-5 mt-5">
                    <p>GTF Enterprise Resource Planning (ERP) Center of Excellence (COE) establishes an integrated
                        process-driven
                        methodology designed for successful implementations, upgrades and maintenance. Our proven
                        practices provides our
                        clients with improved business process efficiencies and enforces standardization by minimizing
                        customizations to
                        reduce implementation and operational costs. Our ERP practice contains cross-functional teams of
                        subject matter
                        experts with business processes, technical developers, solution architects, quality management
                        resources, change
                        management and training teams with experience supporting Federal, State & Local and Commercial
                        clients. Our ERP
                        capabilities include Oracle Federal Financials, Oracle Financials, PRISM, PeopleSoft and
                        Hyperion applications
                        support.</p>
                    <p id="sec1">The GTF leadership of industry experts drive cross-functional integration and create a unique
                        culture to attract
                        top high-performance talent. We maximize the synergies between our clients and team to provide
                        transparency,
                        accuracy and commitment to reach strategic objectives.</p>
                        
                </div>
                <section  class="services-details-area ptb-110">
                    <div class="container">
                        <div class="services-details-overview">
                            <div class="services-details-desc">
                                <h3>Business Process Re-engineering</h3>
                                <p>To support Business Process Re-Engineering (BPR), GTF has developed a proprietary
                                    tool called
                                    FedPrint. This unique BPR tool is a one stop shop to capture end-to-end business
                                    processes,
                                    policies, procedures, manuals, guides, training documents and links to relevant
                                    source information.
                                    Our team combines Six Sigma, Business Process Management (BPM) tools with FedPrint
                                    to identify
                                    logical methods for assessing process inefficiencies, identifying gaps, implementing
                                    improvements
                                    and optimizing performance. BPR efforts take a holistic view of current and future
                                    state to consider
                                    the people, policy and technology impacts to address operational challenges and
                                    achieve strategic
                                    goals. GTF offers distinct, yet complementary, tailored services and advisory
                                    support to
                                    holistically improve the overall business operations.</p>
                                <p>Contact GTF to schedule a demo of FedPrint.</p>
                                <div class="services-details-accordion">
                                    <ul class="accordion">
                                        <li class="accordion-item">
                                            <a class="accordion-title active" href="javascript:void(0)">
                                                <i class="fas fa-plus"></i>
                                                Which material types can you work with?
                                            </a>
                                            <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                                ipsum
                                                suspendisse ultrices gravida.</p>
                                        </li>

                                        <li class="accordion-item">
                                            <a class="accordion-title" href="javascript:void(0)">
                                                <i class="fas fa-plus"></i>
                                                Is Smart Lock required for instant apps?
                                            </a>
                                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                                suspendisse
                                                ultrices gravida.</p>
                                        </li>

                                        <li class="accordion-item">
                                            <a class="accordion-title" href="javascript:void(0)">
                                                <i class="fas fa-plus"></i>
                                                Can I have multiple activities in a single feature?
                                            </a>
                                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                                suspendisse
                                                ultrices gravida.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="services-details-image wow animate__animated animate__fadeInUp">
                                <img src="assets/img/services-details/services-details1.png" alt="image">
                            </div>
                        </div>

                        <div class="services-details-overview">
                            <div class="services-details-image wow animate__animated animate__fadeInUp">
                                <img src="assets/img/services-details/services-details2.png" alt="image">
                            </div>

                            <div class="services-details-desc">
                                <h3 id="sec2">Robotic Process Automation</h3>
                                <p>GTF’s internal Innovation Lab embraced Robotic Process Automation (RPA) as an
                                    affordable solution for
                                    organizations to overcome repetitive, time-consuming and mundane activities. RPA is
                                    now the common
                                    thread for organizations who want employees to focus more on value-added tasks to
                                    compete in the
                                    digital landscape. Our RPA processes involve multiple iterative phases from the
                                    Proof of Concept
                                    (PoC), Pilot, and implementation. After multiple rounds of validations, the
                                    automated solutions are
                                    implemented to minimize human intervention, eliminate redundancy and improve overall
                                    efficiency</p>

                                <div class="services-details-accordion">
                                    <ul class="accordion">
                                        <li class="accordion-item">
                                            <a class="accordion-title active" href="javascript:void(0)">
                                                <i class="fas fa-plus"></i>
                                                Which material types can you work with?
                                            </a>
                                            <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                                ipsum
                                                suspendisse ultrices gravida.</p>
                                        </li>

                                        <li class="accordion-item">
                                            <a class="accordion-title" href="javascript:void(0)">
                                                <i class="fas fa-plus"></i>
                                                Is Smart Lock required for instant apps?
                                            </a>
                                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                                suspendisse
                                                ultrices gravida.</p>
                                        </li>

                                        <li class="accordion-item">
                                            <a class="accordion-title" href="javascript:void(0)">
                                                <i class="fas fa-plus"></i>
                                                Can I have multiple activities in a single feature?
                                            </a>
                                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                                suspendisse
                                                ultrices gravida.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <p><b>RPA Robotic Operations Center (COE)</b></p>
                            <p>Our team of experts will guide organizations in defining the right COE operating models
                                (centric,
                                federated or hybrid) based on the organization structure to ensure RPA is efficient
                                across the
                                organization. The RPA COE provides various services such as Pipeline Management, Process
                                Discovery, Configurations and ongoing maintenance and support. The GTF team will help in
                                defining Governance, security standards, credentialing for Robots, Frameworks, Access
                                controls
                                and management, SDLC methodologies, Define Roles and responsibilities, any new
                                components, and
                                integrations etc.</p>
                        </div>
                        <div class="mt-4">
                            <p><b>RPA Insights</b></p>
                            <p>Robust reporting ensures that an organization does not spend extra hours collecting data
                                and preparing
                                updated reports. We help organization achieve transparency by integrating reporting into
                                the execution
                                of the daily activities. Consistent reporting provides solid data and metrics to track
                                and improve
                                performance. These details confirm whether your goals are met and success is achieved.
                                Building
                                defensible ROI takes time but does not have to be hard.</p>
                        </div>
                        <div class="mt-4">
                            <p><b>RPA + AI = Intelligent Automation Capabilities</b></p>
                            <p>Our team of AI experts will combine the benefits of RPA with cognitive technologies to
                                enable
                                hyperautomation as you scale through your automation journey. RPA + AI will enable your
                                automations to
                                include intelligent document understanding, natural language processing, visual
                                understanding, decision
                                making, analytics, and predictive problem-solving to maximize the operational efficiency
                                across your
                                enterprise.</p>
                        </div>
                        <div class="mt-4">
                            <p><b>Our Key Focus areas include:</b></p>
                            <div>
                                <p>Intelligent Transformation Strategy</p>
                                <p>Enabling RPA + AI</p>
                                <p> AI Technology integration</p>
                                <p>Governance and Reporting</p>
                            </div>
                        </div>
                        <div class="services-details-overview">
                            <div  class="col-lg-12">
                                <h3 id="sec3"><b>Center of Excellence for DevOps</b></h3>
                                <p><b>GTF’s DevOps COE</b> aims at merging development, quality assurance, and
                                    operations (deployment
                                    and
                                    integration) into a cohesive coordinated set of processes. This methodology is a
                                    natural extension
                                    of
                                    Agile, ITSM, and ITIL principles with continuous delivery approaches. GTF
                                    standardizes DevOps
                                    activities
                                    comprising of planning, development, testing, deployment, release, and monitoring to
                                    achieve the
                                    desired
                                    performance metrics.</p>
                            </div>
                        </div>
                        <div class="services-details-overview">
                            <div  class="col-lg-12">
                                <h3 id="sec4"><b>Center of Excellence For Quality Assurance</b></h3>
                                <p>GTF’s Quality Assurance experts derive from a multitude of disciplines resulting from
                                    years of hands
                                    on industry experience. GTF provide our clients with industry best practices
                                    utilizing the latest
                                    tools so that we provide the highest value and ROI. Our QA COE focuses on maximizing
                                    quality
                                    assurance, quality control measures and practices to maximize satisfaction with
                                    software development
                                    deliveries. We understand that better quality minimizes delivery time and lower
                                    costs of operations,
                                    thus resulting in direct benefits to our clients. Our QA practices are tailored to
                                    project
                                    objectives, specialization of testing techniques, and alignment to technologies and
                                    business
                                    practices.</p>
                            </div>
                        </div>
                        <div class="services-details-overview">
                            <div class="col-lg-12">
                                <h3 id="sec5"><b>Training</b></h3>
                                <p>GTF provides end-user training to support our solution implementations through live
                                    classroom
                                    demonstrations, training materials, job aids, and user manuals. Experienced
                                    subject-matter experts
                                    tailor the curriculums to meet the needs of our user community. Our training
                                    methodology allows the
                                    user to listen to the instructor, look at the application and learn the application
                                    with real time
                                    examples of navigation and functionality. We also provide periodic "refresher"
                                    training courses to
                                    users enhance their knowledge of the application to ensure efficiency and accuracy.
                                </p>
                                <p>An experienced subject-matter expert tailors the curriculum to meet the needs of
                                    users and conducts
                                    the training. Our training methodology allows the user to listen to the instructor,
                                    look at the
                                    application and learn the application with real time examples of navigation and
                                    functionality. We
                                    also provide periodic "refresher" training courses to the users based on the need as
                                    it enhances
                                    their knowledge of the application and it also builds confidence in efficiently
                                    using the
                                    application, thus saving costs, and minimizing human errors while entering
                                    transactions.</p>
                            </div>
                        </div>
                        <div class="services-details-overview">
                            <div class="col-lg-12">
                                <h3 id="sec6"><b>Reporting</b></h3>
                                <p><b>Financial Management and Accounting Services</b></p>
                                <p>GTF consistently stays abreast of financial policies, standards, and changes to
                                    financial and
                                    accounting operations in the federal government. Our core financial and accounting
                                    services range
                                    from basic accounting operations such as funds control to comprehensive financial
                                    reporting and
                                    financial statement preparation.</p>
                            </div>
                        </div>
                        <div class="mt-5">
                            <p><b>Financial statement preparation</b></p>
                            <p>GTF is ever vigilant in monitoring, identifying evolving issues, new requirements and is
                                positioned to
                                provide comprehensive support to federal agencies in Federal financial statement
                                preparation and
                                accountability. Our inhouse accountants and financial managers are experienced in
                                implementing - OMB,
                                Treasury, and agency-specific accounting and financial reporting guidance.
                                GTF’s approach is simple yet profound: to be actively involved with our clients at every
                                level and by
                                assisting agencies with creating timely and accurate financial statements.</p>
                        </div>
                        <div class="mt-5">
                            <p><b>Accounting Reconciliation</b></p>
                            <p>Accounting reconciliation includes the creation of accounting codes and related cross
                                validation rules,
                                as well as monthly reconciliations of certain general ledger accounts to detailed
                                records or external
                                sources. At GTF, we support clients to automate the cash reconciliation process,
                                troubleshoot outages,
                                streamline document storage, simplify accounts payables and receivables by aligning each
                                of the
                                processes with federal standards. In addition, GTF emphasizes consistent and timely
                                account
                                reconciliation to be successful, as reconciling not only flags any discrepancies, but
                                also provides with
                                critical data for key decision-making.</p>
                        </div>
                        <div class="mt-5">
                            <p><b>Treasury Reporting</b></p>
                            <p>GTF’s goal is to achieve organization-wide accountability with clean and transparent
                                reporting by
                                consistently applying accounting rules across all financial transactions. Our expert
                                General Ledger
                                Reporting services includes the preparation of financial reports for both internal and
                                external use such
                                as financial reporting to Treasury, OMB, GTAS, DATA Act, CFRS & CFRS, financial
                                statements and
                                footnotes.</p>
                        </div>
                    </div>
                </section>
                <div class="project-details-info">
                    <div class="single-info-box">
                        <h4>Client</h4>
                        <span>James Anderson</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Category</h4>
                        <span>Network, Marketing</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Date</h4>
                        <span>February 28, 2020</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Share</h4>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-youtube"></i></a></li>
                        </ul>
                    </div>

                    <div class="single-info-box">
                        <a href="#" class="btn btn-primary" target="_blank">Live Preview</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Projects Details Area -->
