<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <!-- <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div> -->
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>ABOUT OUR COMPANY</span>
                    <hr class="border-5 w-25">
                    <p>GlobalTekForcecom, Inc. (GTF) is a certified woman and minority owned small business providing
                        full-service management consulting and information technology services. We provide a full range
                        of services specializing in Financial Management, ERP systems and integrations, Infrastructure
                        Management, Robotics Process Automation (RPA) assisting our clients to implement, modernize and
                        maintain Accounting, Regulatory and Mandatory compliance by gaining efficiencies, reduce costs
                        standardizations, and enhanced security.</p>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/img/goal (2).jpg" class="w-25 rounded" alt="">
                            <h3 class="ms-3">Goals</h3>
                        </div>
                        <p>Accomplish mission critical strategic objectives by streamlining processes, integrating
                            enterprise wide modernizations with emerging technologies.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/img/mission.jpg" class="w-25 rounded" alt="">
                            <h3 class="ms-3">Mission</h3>
                        </div>
                        <p>To maximize synergy between people, process and technology to provide distinguished solutions
                            and services to our clients.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="about-text">
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/img/vission.jpg" class="w-25 rounded" alt="">
                            <h3 class="ms-3">Vision</h3>
                        </div>
                        <p>Accelerate business operations by tailoring end-to-end business solutions</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start FunFacts Area -->
<section class="funfacts-area ptb-110 bg-f2feee">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="60"></div>
                    <span>Design</span>
                    <h3>Codes</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="75"></div>
                    <span>Our happy</span>
                    <h3>Clients</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="85"></div>
                    <span>Projects</span>
                    <h3>Design</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4 offset-lg-0 offset-md-0 offset-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="95"></div>
                    <span>Completed</span>
                    <h3>Projects</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts Area -->

<!-- Start Team Area -->
<section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Experts</span>
                <h2>Meet Our Leadership Preparing For Your Success</h2>
                <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics Process
                    automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative methodology
                    enhances utilization of COTS functionality for automating configurations, standard processes and
                    robust reporting.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title4.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Joe Root</h3>
                        <span>Lead Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Daisy Lucy</h3>
                        <span>Lead Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start Testimonials Area -->
<!-- <section class="testimonials-area">
    <div class="container-fluid p-0">
        <div class="testimonials-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="testimonials-inner-content">
                <div class="testimonials-slides owl-carousel owl-theme">
                    <div class="single-testimonials-item">
                        <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics
                            Process automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative
                            methodology enhances utilization of COTS functionality for automating configurations,
                            standard processes and robust reporting.
                        </p>

                        <div class="client-info">
                            <img src="assets/img/author1.jpg" alt="image">
                            <h3>John Doe</h3>
                            <span>Marketing</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.
                        </p>

                        <div class="client-info">
                            <img src="assets/img/author2.jpg" alt="image">
                            <h3>Steven Smith</h3>
                            <span>Marketing</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.
                        </p>

                        <div class="client-info">
                            <img src="assets/img/author3.jpg" alt="image">
                            <h3>Sarah Taylor</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>

                <div class="circle-map"><img src="assets/img/circle-map3.png" alt="image"></div>
            </div>

            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                    class="flaticon-play-button"></i></a>
        </div>
    </div>
</section> -->
<!-- End Testimonials Area -->
