<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Grants Close-out</h2>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><a routerLink="/federal-grant">Federal Grants Management & Grants Management</a></li>
                <li>Grants Close-out</li>
            </ul>
        </div>
    </div>
</div>


<section class="grant-execution">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 grant-execution-text">
          <h2>Grants Close-out</h2>
          <p>   
            Grant recipients are legally responsible to continue to perform the conditions of a grant until the grant is completely closed out, which may impose a heavy burden on some recipients. GTF helps to develop process improvements for submitting FFRs to mitigate this type of risk. FFRs are a critical part of the close-out process, which we streamline by reducing the backlog of SF-425 forms that often accumulate during a grant’s lifecycle. Our dedication to detail and diligent efforts to keep our client organizations current on their reporting obligations consistently results in simple, clean close-out process experiences.
          </p>
          <ul class="list-unstyled grant-benefits">
            <li>
              <i class="fas fa-check-circle"></i> Meticulous and Detailed Service
            </li>
            <li>
              <i class="fas fa-star"></i> Outstanding Work Quality
            </li>
            <li>
              <i class="fas fa-layer-group"></i> Comprehensive Grant Management Solutions
            </li>
          </ul>
          <a href="#" class="btn btn-primary">Learn More About Our Services</a>
        </div>
        <div class="col-md-6 grant-execution-image">
          <img src="../../../../assets/img/2148525276.jpg" alt="Grant Execution Image">
        </div>
      </div>
    </div>
  </section>