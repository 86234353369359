import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-robotics-process-automation',
  templateUrl: './robotics-process-automation.component.html',
  styleUrls: ['./robotics-process-automation.component.scss']
})
export class RoboticsProcessAutomationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
