import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {

    // location: any;
    @ViewChild('servicesDropdown') servicesDropdown: ElementRef;
    showDropdown: boolean = false;
    constructor(
        // public router: Router,
        // location: Location,
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        // this.router.events
        // .subscribe((event) => {
        //     if ( event instanceof NavigationEnd ) {
        //         this.location = this.router.url;
        //     }
        // });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
      if (!this.servicesDropdown.nativeElement.getBoundingClientRect().top) {
        this.hideDropdown();
      }
    }
  
    hideDropdown() {
      this.showDropdown = false;
    }

}
