import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-maintenance-upgrades',
  templateUrl: './software-maintenance-upgrades.component.html',
  styleUrls: ['./software-maintenance-upgrades.component.scss']
})
export class SoftwareMaintenanceUpgradesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
