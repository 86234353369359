<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div *ngIf="location === '/'" class="subscribe-area">
            <h3>Subscribe To Our Newsletter</h3>

            <form class="newsletter-form">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <input type="text" class="form-control" placeholder="Your Name" name="name" id="name">
                    </div>

                    <div class="col-lg-5 col-md-6 col-sm-6">
                        <input type="email" class="form-control" placeholder="Your Email" name="EMAIL" required
                            autocomplete="off">
                    </div>

                    <div class="col-lg-3 col-md-12 col-sm-12">
                        <button type="submit">Subscribe Now <i class="flaticon-paper-plane"></i></button>
                    </div>
                </div>
            </form>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="/assets/img/GTF Logo.jpg" class="h-25 w-25" alt="image"></a>
                        <p>GTF is a minority owned small business that thrives on innovation and project excellence. We
                            believe in the best of breed customer service in all the projects that we undertake.</p>
                    </div>

                    <ul class="social">
                        <div class="d-flex">
                            <li><a href="#" target="_blank"><i class="fa fa-envelope-o" aria-hidden="true"></i></a></li>
                            <p class="mt-2"> Email :</p>
                        </div>
                        <p>info@globaltekforce.com</p>
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-youtube"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>CAREERS</h3>

                    <ul class="footer-services-list">
                        <li><a routerLink="/">Financial Analyst</a></li>
                        <div class="d-flex">
                            <i class="fa fa-calendar-o fs-5 calender" aria-hidden="true"></i>
                            <p class="ms-3"><i>May 22, 2020</i></p>
                        </div>
                        <div class=" text-muted border border-1 my-2"></div>
                        <li><a routerLink="/">Data Conversion Specialist</a></li>
                        <div class="d-flex">
                            <i class="fa fa-calendar-o fs-5 calender" aria-hidden="true"></i>
                            <p class="ms-3"><i>May 22, 2020</i></p>
                        </div>
                        <div class="border border-1 my-2"></div>
                        <li><a routerLink="/">Data Conversion SME</a></li>
                        <div class="d-flex">
                            <i class="fa fa-calendar-o fs-5 calender" aria-hidden="true"></i>
                            <p class="ms-3"><i>May 22, 2020</i></p>
                        </div>
                        <div class="border border-1 my-2"></div>
                        <!-- <li><a routerLink="/">Desktop Applications</a></li>
                        <li><a routerLink="/">Mobile Applications</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>QUICK LINKS</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/">About us</a></li>
                        <li><a routerLink="/">Contract Vehicles</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Clients</a></li>
                        <li><a routerLink="/">Careers</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Business Address</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12613.095901944322!2d-122.41685973871505!3d37.783618581549995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808c21a4541f%3A0x76b15070f9742507!2sExcitel%20Pay!5e0!3m2!1sen!2sin!4v1692277057952!5m2!1sen!2sin" width="300" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>NEWSLETTER</h3>

                    <ul class="footer-contact-list">
                        <div class="form">
                            <input type="text" class="mt-3" name="name" placeholder="Name *" id="name"
                                fdprocessedid="fg5hdn">
                        </div>
                        <div>
                            <input type="email" class="mt-3" name="email" placeholder="Email *" id="email"
                                fdprocessedid="setzkd">
                        </div>
                        <div>
                            <button type="button" class="btn btn-light sub mt-4 rounded">SUBSCRIBE</button> -->
                        <!-- </div> -->
                        <!-- <li><span>Phone: </span> <a href="tel:+44587154756">+44 587 154756</a></li>
                        <li><span>Fax: </span> <a href="tel:+44587154756">+44 785 4578964</a></li> -->
                    <!-- </ul>
                </div>
            </div> -->
            
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; Copyright {{currentYear}} . All Rights Reserved.</p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
