import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication-management',
  templateUrl: './communication-management.component.html',
  styleUrls: ['./communication-management.component.scss']
})
export class CommunicationManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
