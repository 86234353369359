import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  // @ViewChild('projectsDetails') projectsDetails: ElementRef;
  @ViewChild('projectsDetails', { static: false }) projectsDetails!: ElementRef;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
  }

  
  // ngAfterViewInit() {
  //   this.route.fragment.subscribe(fragment => {
  //     if (fragment) {
  //       const element = this.projectsDetails.nativeElement.querySelector(`#${fragment}`);
  //       if (element) {
  //         element.scrollIntoView({ behavior: 'smooth' });
  //       }
  //     }
  //   });
  // }

    

//   ngAfterViewInit() {
//     this.route.fragment.subscribe(fragment => {
//         if (fragment) {
//             const element = this.projectsDetails.nativeElement.querySelector(`#${fragment}`);
//             console.log('const element',  element)
//             if (element) {
//                 element.scrollIntoView({ behavior: 'smooth' });
//             }
//         }
//     });
// }
  
}