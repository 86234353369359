<!-- Start Navbar Area -->


<div class="container">

    <div class="navbar-area">
        <div class="crimo-nav crimo-nav navbar-style-two">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">

                    <a class="navbar-brand" routerLink="/">
                        <img src="../../../../assets/img/GTF-large.png" alt="logo" height="70px">
                    </a>

                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse mean-menu justify-content-end" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    Home <i class="fas fa-chevron-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                    <!-- <li class="nav-item">
                                        <a routerLink="/" routerLinkActive="active" class="nav-link">IT Startups</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/index-2" routerLinkActive="active" class="nav-link">IT
                                            Company</a>
                                    </li> -->

                                    <li class="nav-item">
                                        <a routerLink="/index-3" routerLinkActive="active" class="nav-link">IT
                                            Services</a>
                                    </li>

                                    <!-- <li class="nav-item">
                                        <a routerLink="/index-4" routerLinkActive="active" class="nav-link">IT
                                            Solutions</a>
                                    </li> -->
                                </ul>
                            </li>

        
                            <li class="nav-item services-dropdown" (mouseenter)="showDropdown = true"
                                (mouseleave)="hideDropdown()" #servicesDropdown>
                                <a routerLink="/" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    Services <i class="fas fa-chevron-down"></i>
                                </a>
                                <div class="dropdown-menu" *ngIf="showDropdown">
                                  <ul class="right-line">
                                    <li><a routerLink="/oracle-cloude-application" class="dropdown-item heading">ERP Solutions</a></li>
                                    <li><a routerLink="/oracle-cloud-erp" class="dropdown-item" >	
                                        Financial Systems</a></li>
                                    <li><a routerLink="/oracle-hcm-cloud" class="dropdown-item" >Acquisitions & <br>Procurement Systems</a></li>
                                    <li><a routerLink="/oracle-epm-cloud" class="dropdown-item" >	
                                        Asset Management <br> Systems</a></li>
                      
                                  </ul>
                                  <ul class="right-line">
                                    <li><a class="dropdown-item heading" routerLink="/">Federal Financial & <br>Grants Management</a></li>
                                    <li><a class="dropdown-item" routerLink="/grant-execution">Grants Execution</a></li>
                                    <li><a class="dropdown-item" routerLink="/grant-accounting">Grants Accounting</a></li>
                                    <li><a class="dropdown-item" routerLink="/grant-close-out">Grants Close-out</a></li>
                                    <li><a class="dropdown-item" routerLink="/audit-support">Audit Support</a></li>
                      
                                  </ul>
                                  <ul class="right-line">
                                    <li><a class="dropdown-item heading" routerLink="/">Software <br> Development</a></li>
                                    <li><a class="dropdown-item" href="/custom-software">Custom Software <br> Development</a></li>
                                    <li><a class="dropdown-item" href="/enterprise-software">	
                                        Enterprise Software <br> Development</a></li>
                                    <li><a class="dropdown-item" href="/cloud-based">	
                                        Cloud-Based Software <br> Development</a></li>
                                    <li><a class="dropdown-item" href="/software-prototyping">Software Prototyping</a></li>
                                    <li><a class="dropdown-item" href="/software-integration">Software Integration</a></li>
                                    <li><a class="dropdown-item" href="/software-maintenace">	
                                        Software Maintenance <br> & Upgrades</a></li>
                      
                                  </ul>
                                  <ul class="right-line">
                                    <li><a class="dropdown-item heading" routerLink="/">Information Technology <br> Services Management <br>(ITSM)</a></li>
                                    <li><a class="dropdown-item" href="/helpdesk">Helpdesk support</a></li>
                                    <li><a class="dropdown-item" href="/release">Release Management</a></li>
                                    <li><a class="dropdown-item" href="/cicd">CI/CD</a></li>
                      
                                  </ul>
                                  <ul class="right-line">
                                    <li><a class="dropdown-item heading" routerLink="/">Digitial <br> Transformation</a></li>
                                    <li><a class="dropdown-item" href="/robotics">Robotics Process <br> Automation</a></li>
                                    <li><a class="dropdown-item" href="/artificial">Artificial Intellegence</a></li>
                                    <li><a class="dropdown-item" href="/machine-learning">Machine Learning</a></li>
                      
                                  </ul>
                                  <ul class="right-line">
                                    <li><a class="dropdown-item heading" routerLink="/">	
                                        Organization <br> Change</a></li>
                                    <li><a class="dropdown-item" href="/communication-management">	
                                        Communication <br> Management</a></li>
                                    <li><a class="dropdown-item" href="/training">Training</a></li>
                                    <li><a class="dropdown-item" href="/content-management">Content Management</a></li>
                                        
                                    <ul class="top-line">
                                        <li><a class="dropdown-item heading" routerLink="/">	
                                            Security & <br> Vulnerability <br> Management</a></li>
                                        <li><a class="dropdown-item" href="/eol">	
                                            End of Life <br> (EOL) Management</a></li>
                                      </ul>
                                  </ul>
                                  <ul >
                                    <li><a class="dropdown-item heading" routerLink="/">	
                                        Infrastructure <br> Management</a></li>
                                    <li><a class="dropdown-item" href="/on-prem">	
                                        On-Prem</a></li>
                                    <li><a class="dropdown-item" href="/cloud">Cloud</a></li>
                                    <li><a class="dropdown-item" routerLink="/fiscam">FISCAM</a></li>

                                    <ul class="top-line">
                                        <li><a class="dropdown-item heading" routerLink="/">	
                                            Program <br> Management</a></li>
                                        <li><a class="dropdown-item" href="/waterfall">	
                                            Waterfall</a></li>
                                        <li><a class="dropdown-item" href="/agile">Agile</a></li>
                          
                                      </ul>
                                  </ul>
                                </div>
                              </li>

                            <!-- <li class="nav-item services-dropdown" (mouseenter)="showDropdown = true"
                                (mouseleave)="hideDropdown()" /servicesDropdown>
                                <a routerLink="/services" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    Services <i class="fas fa-chevron-down"></i>
                                </a>
                                <div class="dropdown-menu" *ngIf="showDropdown">
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a routerLink="/oracle-cloude-application"
                                                    class="dropdown-item heading">ERP Solutions</a></li>
                                            <li><a routerLink="/oracle-cloud-erp" class="dropdown-item"
                                                    href="#">Financial Systems</a></li>
                                            <li><a routerLink="/oracle-hcm-cloud" class="dropdown-item"
                                                    href="#">Acquisitions & Procurement Systems</a></li>
                                            <li><a routerLink="/oracle-epm-cloud" class="dropdown-item" href="#">Asset
                                                    Management Systems</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a class="dropdown-item heading" routerLink="/federal-grant">Federal
                                                    Financial & Grants Management</a></li>
                                            <li><a class="dropdown-item" routerLink="/grant-execution">Grants
                                                    Execution</a></li>
                                            <li><a class="dropdown-item" routerLink="/grant-accounting">Grants
                                                    Accounting</a></li>
                                            <li><a class="dropdown-item" routerLink="/grant-close-out">Grants
                                                    Close-out</a></li>
                                            <li><a class="dropdown-item" routerLink="/audit-support">Audit Support</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a class="dropdown-item heading" href="#">Software Development</a></li>
                                            <li><a class="dropdown-item" href="#">Custom Software Development</a></li>
                                            <li><a class="dropdown-item" href="#">Enterprise Software Development</a>
                                            </li>
                                            <li><a class="dropdown-item" href="#">Cloud-Based Software Development</a>
                                            </li>
                                            <li><a class="dropdown-item" href="#">Software Prototyping</a></li>
                                            <li><a class="dropdown-item" href="#">Software Integration</a></li>
                                            <li><a class="dropdown-item" href="#">Software Maintenance & Upgrades</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a class="dropdown-item heading" href="#">Information Technology
                                                    Services Management (ITSM)</a></li>
                                            <li><a class="dropdown-item" href="#">Helpdesk support</a></li>
                                            <li><a class="dropdown-item" href="#">Release Management</a></li>
                                            <li><a class="dropdown-item" href="#">CI/CD</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a class="dropdown-item heading" href="#">Digital Transformation</a>
                                            </li>
                                            <li><a class="dropdown-item" href="#">Robotics Process Automation</a></li>
                                            <li><a class="dropdown-item" href="#">Artificial Intelligence</a></li>
                                            <li><a class="dropdown-item" href="#">Machine Learning</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a class="dropdown-item heading" href="#">Organization Change</a></li>
                                            <li><a class="dropdown-item" href="#">Communication Management</a></li>
                                            <li><a class="dropdown-item" href="#">Training</a></li>
                                            <li><a class="dropdown-item" href="#">Content Management</a></li>
                                            <li><a class="dropdown-item" href="#">Security & Vulnerability
                                                    Management</a></li>
                                            <li><a class="dropdown-item" href="#">End of Life (EOL) Management</a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-column">
                                        <ul>
                                            <li><a class="dropdown-item heading" href="#">Infrastructure Management</a>
                                            </li>
                                            <li><a class="dropdown-item" href="#">On-Prem</a></li>
                                            <li><a class="dropdown-item" href="#">Cloud</a></li>
                                            <li><a class="dropdown-item" routerLink="FISCAM">FISCAM</a></li>
                                            <li><a class="dropdown-item" href="#">Program Management</a></li>
                                            <li><a class="dropdown-item" href="#">Waterfall</a></li>
                                            <li><a class="dropdown-item" href="#">Agile</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li> -->

                            <li class="nav-item dropdown">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    About <i class="fas fa-chevron-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/about" routerLinkActive="active" class="nav-link">About Us</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="#" routerLinkActive="active" class="nav-link">Contract
                                            Vehicles</a>
                                    </li>

                                    <!-- <li class="nav-item">
                                        <a routerLink="/not-found" routerLinkActive="active" class="nav-link">404
                                            Error</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/coming-soon" routerLinkActive="active" class="nav-link">Coming
                                            Soon</a>
                                    </li> -->

                                    <!-- <li class="nav-item">
                                        <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                    </li> -->
                                </ul>
                            </li>

                            <li class="nav-item dropdown">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    Projects <i class="fas fa-chevron-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                    <!-- <li class="nav-item">
                                        <a routerLink="/projects" routerLinkActive="active"
                                            class="nav-link">Projects</a>
                                    </li> -->

                                    <li class="nav-item">
                                        <a routerLink="/project-details" routerLinkActive="active"
                                            class="nav-link">Projects</a>
                                    </li>
                                </ul>
                            </li>

                            <!-- <li class="nav-item dropdown">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    Services <i class="fas fa-chevron-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/services" routerLinkActive="active"
                                            class="nav-link">Services</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/service" routerLinkActive="active"
                                            class="nav-link">Services Details</a>
                                    </li>
                                </ul>
                            </li> -->



                            <!-- <li class="nav-item dropdown">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle" style="color: white"
                                    data-bs-toggle="dropdown">
                                    Blog <i class="fas fa-chevron-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog
                                            Details</a>
                                    </li>
                                </ul>
                             </li> -->

                            <li class="nav-item">
                                <a routerLink="" routerLinkActive="active" class="nav-link"
                                    style="color: white;">Careers </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="" routerLinkActive="active" class="nav-link" style="color: white;">Our
                                    Network <i class="fas fa-chevron-down"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="#" routerLinkActive="active" class="nav-link">Clients</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="#" routerLinkActive="active" class="nav-link">Partners</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div class="others-options">
                            <div class="option-item" style="color: white">
                                <i class="search-btn flaticon-search"></i>
                                <i class="close-btn fas fa-times"></i>

                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">
                                            <button class="search-button" type="submit">
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <a routerLink="contact" class="btn btn-primary" style="margin-top: 20px;">Contact</a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>


</div>

<!-- End Navbar Area -->

<!-- START HEADER SECTION -->

<!-- <div class="section">
    <h1 class="head fs-3"><b>ACCELERATING BUSINESS OPERATIONS<br>
            WITH INNOVATIVE SOLUTIONS AND SERVICES</b></h1>
    <p class="pre">GTF provides quality services and innovative solutions to support organizational evolution.</p>

    <div class="get h-25">
        <a class="btn btn-primary" href="https://profun-ng.envytheme.com/">Get Started</a>
    </div>

    <div class="row mt-5">
        <div class="col-lg-4 col-md-6 col-sm-6">

            <h3 class="h3 data">

                Analyze Your Data
                <p class="para">GTF provides quality services and innovative solutions to support organizational
                    evolution.</p>
            </h3>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <h3 class="h3 data">Customized Plan
                <p class="para">GTF provides quality services and innovative solutions to support organizational
                    evolution.</p>
            </h3>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <h3 class="h3 data">Implement Solution
                <p class="para">GTF provides quality services and innovative solutions to support organizational
                    evolution.</p>
            </h3>
        </div>
    </div>
</div> -->


<!-- END HEADER SECTION -->