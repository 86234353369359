import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ 
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
                }
    ]
   
})

export class AppComponent implements OnInit, OnDestroy {
    // location: any;
    routerSubscription: any;
    hideHeaderFooter: boolean = false;
    location: string = ''; // Initialize the location property

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        // router.events
        // .pipe(filter(event => event instanceof NavigationEnd))
        // .subscribe((event: NavigationEnd) => {
        //   const isComingSoon = event.url.includes('/coming-soon');
        //   const isNotFound = event.url.includes('/not-found');
        //   this.hideHeaderFooter = isComingSoon || isNotFound;
        // });

    }

    // ngOnInit(): void {
    //     this.recallJsFuntions();
    //     this.router.events
    //       .pipe(filter(event => event instanceof NavigationEnd))
    //       .subscribe((event: NavigationEnd) => {
    //         const urlWithoutLeadingSlash = event.urlAfterRedirects.substring(1);
    //         if (!this.isRouteRecognized(urlWithoutLeadingSlash)) {
    //           this.location = '/not-found';
    //         } else {
    //           this.location = event.urlAfterRedirects;
    //         }
    //       });
    //   }
    
    ngOnInit(): void {
        this.recallJsFuntions();
        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => {
            const urlWithoutLeadingSlash = event.urlAfterRedirects.substring(1);
            if (urlWithoutLeadingSlash.includes('#')) {
              const fragment = urlWithoutLeadingSlash.split('#')[1];
              if (fragment) {
                this.location = `/${fragment}`;
              } else {
                this.location = event.urlAfterRedirects;
              }
            } else {
              this.location = event.urlAfterRedirects;
            }
          });
      }

      shouldDisplayHeader(): boolean {
        return this.location !== '/coming-soon' && this.location !== '/not-found';
      }
    
      private isRouteRecognized(route: string): boolean {
        const recognizedRoutes = this.router.config.map(route => route.path);
        return recognizedRoutes.includes(route);
      }

      ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
 
//   shouldDisplayHeader(): boolean {
//     return this.location !== '/not-found';
//   }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

  
}
