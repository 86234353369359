<!-- Start IT Solutions Slides Area -->
<div class="it-solutions-slides owl-carousel owl-theme">
    <div class="it-solutions-item">
        <div class="container">
            <div class="it-solutions-banner-content">
                <h1>Solve Busines Challenges With IT</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                    accumsan lacus vel facilisis.</p>
                <div class="btn-box">
                    <a href="#" class="btn btn-primary">Read More</a>
                    <a href="#" class="btn btn-light">Get Started</a>
                </div>
            </div>
        </div>
    </div>

    <div class="it-solutions-item bg-2">
        <div class="container">
            <div class="it-solutions-banner-content">
                <h1>Solve Busines Challenges With IT</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                    accumsan lacus vel facilisis.</p>
                <div class="btn-box">
                    <a href="#" class="btn btn-primary">Read More</a>
                    <a href="#" class="btn btn-light">Get Started</a>
                </div>
            </div>
        </div>
    </div>

    <div class="it-solutions-item bg-3">
        <div class="container">
            <div class="it-solutions-banner-content">
                <h1>Solve Busines Challenges With IT</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                    accumsan lacus vel facilisis.</p>
                <div class="btn-box">
                    <a href="#" class="btn btn-primary">Read More</a>
                    <a href="#" class="btn btn-light">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IT Solutions Slides Area -->

<!-- Start Features Style Area -->
<div class="features-style-area pt-110 pb-80">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="features-style-card text-start">
                    <div class="features-image">
                        <img src="assets/img/it-services-home/features-1.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>IT Management</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut
                            labore et dolore</p>
                        <a href="#" class="features-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-style-card text-start">
                    <div class="features-image">
                        <img src="assets/img/it-services-home/features-2.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Development</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut
                            labore et dolore</p>
                        <a href="#" class="features-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-style-card text-start">
                    <div class="features-image">
                        <img src="assets/img/it-services-home/features-3.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>UX/UI Design</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut
                            labore et dolore</p>
                        <a href="#" class="features-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Features Style Area -->

<!-- Start About Wrap Area -->
<div class="about-wrap-area pb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-image-with-style">
                    <img src="assets/img/it-solutions-home/about.jpg" alt="image">

                    <div class="wrap-shape">
                        <img src="assets/img/it-solutions-home/about-shape.png" alt="image">
                    </div>
                    <div class="wrap-shape-2">
                        <img src="assets/img/it-solutions-home/about-shape-2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-content">
                    <span>ABOUT OUR COMPANY</span>

                    <p>GTF is a woman and minority owned small business and SWaM(Small,Woman-Owned, and Minority-Owned
                        Business) is a full-service information technology and management consulting firm, certified ISO
                        9001:2015 for Quality Management Systems, ISO:27001:2013 for Information Security Management
                        Systems.</p>
                    <p>We focus on providing services across financial management and accounting areas which enables
                        government organizations and companies select, implement, modernize, integrate and maintain
                        critical end-to-end business solutions. GTF helps our customers expand financial operations’,
                        increase efficiency, effectiveness, level of accountability, minimize costs, comply with laws, &
                        regulations. Based on our experience within both the Government and Commercial enterprises, GTF
                        understands our client needs and the dedication required to get the job done precisely. We
                        strive to provide world-class solutions to our customers through our own independent research or
                        from our past experience. GTF recognizes the need for improvement therefore we immerse ourselves
                        in our customers’ daily business operations to learn more about environments, issues, and
                        challenges.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Desktop Applications</li>
                                <li><i class="fa-solid fa-check-double"></i> Mobile Applications</li>
                                <li><i class="fa-solid fa-check-double"></i> Cyber Security</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Web Development</li>
                                <li><i class="fa-solid fa-check-double"></i> Product Engineering</li>
                                <li><i class="fa-solid fa-check-double"></i> Data Analytics</li>
                            </ul>
                        </div>
                    </div>
                    <div class="about-btn">
                        <a href="#" class="btn btn-primary">View More About Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Wrap Area -->

<!-- Start Services Wrap Area -->
<div class="services-wrap-area pt-110">
    <div class="container">
        <div class="section-title-style-two text-center">
            <div class="content">
                <span>OUR SERVICES</span>
                <h2>We Offer Professional IT Solutions For Business</h2>
            </div>

            <div class="text">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua ultrices gravida.</p>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span>1</span>
                    <h3>
                        <a href="single-services.html">Product Engineering</a>
                    </h3>
                    <p>Quis ipsum suspendisse ultrices gravida commodo viverra maecenas accumsan lacus adipiscing
                        aliqua.</p>
                    <a href="single-services.html" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span>2</span>
                    <h3>
                        <a href="single-services.html">UX/UI Design</a>
                    </h3>
                    <p>Quis ipsum suspendisse ultrices gravida commodo viverra maecenas accumsan lacus adipiscing
                        aliqua.</p>
                    <a href="single-services.html" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span>3</span>
                    <h3>
                        <a href="single-services.html">Big Data Analysis</a>
                    </h3>
                    <p>Quis ipsum suspendisse ultrices gravida commodo viverra maecenas accumsan lacus adipiscing
                        aliqua.</p>
                    <a href="single-services.html" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span>4</span>
                    <h3>
                        <a href="single-services.html">Desktop Application</a>
                    </h3>
                    <p>Quis ipsum suspendisse ultrices gravida commodo viverra maecenas accumsan lacus adipiscing
                        aliqua.</p>
                    <a href="single-services.html" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span>5</span>
                    <h3>
                        <a href="single-services.html">Mobile Application</a>
                    </h3>
                    <p>Quis ipsum suspendisse ultrices gravida commodo viverra maecenas accumsan lacus adipiscing
                        aliqua.</p>
                    <a href="single-services.html" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span>6</span>
                    <h3>
                        <a href="single-services.html">Cyber Security</a>
                    </h3>
                    <p>Quis ipsum suspendisse ultrices gravida commodo viverra maecenas accumsan lacus adipiscing
                        aliqua.</p>
                    <a href="single-services.html" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>
        </div>

        <div class="funfacts-style-wrap-inner-box ptb-110">
            <div class="row justify-content-center">
                <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                    <div class="single-funfacts">
                        <div class="circlechart" data-percentage="60"></div>
                        <span>Design</span>
                        <h3>Codes</h3>
                    </div>
                </div>

                <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                    <div class="single-funfacts">
                        <div class="circlechart" data-percentage="75"></div>
                        <span>Our happy</span>
                        <h3>Clients</h3>
                    </div>
                </div>

                <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                    <div class="single-funfacts">
                        <div class="circlechart" data-percentage="85"></div>
                        <span>Projects</span>
                        <h3>Design</h3>
                    </div>
                </div>

                <div class="col-6 col-lg-3 col-md-3 col-sm-4 offset-lg-0 offset-md-0 offset-sm-4">
                    <div class="single-funfacts">
                        <div class="circlechart" data-percentage="95"></div>
                        <span>Completed</span>
                        <h3>Projects</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Wrap Area -->

<!-- Start Testimonials Wrap Area -->
<section class="testimonials-wrap-area ptb-110">
    <div class="container">
        <div class="testimonials-wrap-slides owl-carousel owl-theme">
            <div class="single-testimonials-card">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra. It is a long
                    established fact that a reader will be distracted by the readable content of a page when looking at
                    its layout.</p>

                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author1.jpg" alt="image">

                    <div class="title">
                        <h3>John Doe</h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="single-testimonials-card">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra. It is a long
                    established fact that a reader will be distracted by the readable content of a page when looking at
                    its layout.</p>

                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author2.jpg" alt="image">

                    <div class="title">
                        <h3>Steven Smith</h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="single-testimonials-card">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra. It is a long
                    established fact that a reader will be distracted by the readable content of a page when looking at
                    its layout.</p>

                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author3.jpg" alt="image">

                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonials-wrap-map">
        <img src="assets/img/it-services-home/map.png" alt="image">
    </div>
</section>
<!-- End Testimonials Wrap Area -->

<!-- Start Projects Wrap Area -->
<section class="projects-wrap-area pb-80">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-4 col-md-12">
                <div class="projects-wrap-content">
                    <span>RECENT PROJECTS</span>
                    <h2>Proud Projects That Make Us Stand Out</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis suspendisse ultrices gravida. Risus commodo viverra maecenas
                        accumsan lacus vel facilisis.</p>

                    <div class="projects-wrap-btn">
                        <a href="projects.html" class="btn btn-primary">View More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="projects-wrap-slides owl-carousel owl-theme">
                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/projects/projects1.jpg" alt="image"></a>

                        <div class="projects-content">
                            <span>Design/Idea</span>
                            <h3><a href="#">Flutter Development</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                viverra maecenas.</p>
                            <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                        </div>
                    </div>

                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/projects/projects2.jpg" alt="image"></a>

                        <div class="projects-content">
                            <span>Design/Development</span>
                            <h3><a href="#">Application Development</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                viverra maecenas.</p>
                            <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                        </div>
                    </div>

                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/projects/projects3.jpg" alt="image"></a>

                        <div class="projects-content">
                            <span>Design</span>
                            <h3><a href="#">Web Development</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                viverra maecenas.</p>
                            <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                        </div>
                    </div>

                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/projects/projects4.jpg" alt="image"></a>

                        <div class="projects-content">
                            <span>Idea</span>
                            <h3><a href="#">Big Data Analysis</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                viverra maecenas.</p>
                            <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                        </div>
                    </div>

                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/projects/projects5.jpg" alt="image"></a>

                        <div class="projects-content">
                            <span>Guard</span>
                            <h3><a href="#">Cyber Security</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                viverra maecenas.</p>
                            <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Projects Wrap Area -->

<!-- Start Video Wrap Area -->
<div class="video-wrap-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container-fluid">
        <div class="video-wrap-content">
            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                    class="flaticon-play-button"></i></a>
        </div>
    </div>
</div>
<!-- End Video Wrap Area -->

<!-- Start Partner Area -->
<div class="partner-area bg-with-gradient-color">
    <div class="container">
        <div class="partner-list">
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="image"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Team Wrap Area -->
<section class="team-wrap-area pt-110 pb-80">
    <div class="container">
        <div class="section-title-style-two text-center">
            <div class="content">
                <span>OUR EXPERTS</span>
                <h2>Meet Our Leadership Preparing For Your Success</h2>
            </div>

            <div class="text">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua ultrices gravida.</p>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-card">
                    <div class="team-image">
                        <img src="assets/img/it-services-home/team-1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-card">
                    <div class="team-image">
                        <img src="assets/img/it-services-home/team-2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-card">
                    <div class="team-image">
                        <img src="assets/img/it-services-home/team-3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Joe Root</h3>
                        <span>Lead Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Wrap Area -->

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <h3>Subscribe To Our Newsletter</h3>

            <form class="newsletter-form" data-toggle="validator">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <input type="text" class="form-control" placeholder="Your Name" id="name">
                    </div>

                    <div class="col-lg-5 col-md-6 col-sm-6">
                        <input type="email" class="form-control" placeholder="Your Email" required autocomplete="off">
                    </div>

                    <div class="col-lg-3 col-md-12 col-sm-12">
                        <button type="submit">Subscribe Now <i class="flaticon-paper-plane"></i></button>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div id="validator-newsletter" class="form-result"></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->

<!-- Start Blog Wrap Area -->
<div class="blog-wrap-area ptb-110">
    <div class="container">
        <div class="section-title-style-two text-center">
            <div class="content">
                <span>LATEST NEWS</span>
                <h2>Our Latest Insights Are On Top All Times</h2>
            </div>

            <div class="text">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua ultrices gravida.</p>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 p-0">
                <div class="single-blog-item" style="background-image: url(assets/img/blog/blog1.jpg)">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a href="#">Admin</a></li>
                                <li>August 15, 2022</li>
                            </ul>
                        </div>

                        <h3><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod...</p>
                        <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 p-0">
                <div class="single-blog-item active" style="background-image: url(assets/img/blog/blog2.jpg)">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a href="#">Admin</a></li>
                                <li>August 15, 2022</li>
                            </ul>
                        </div>

                        <h3><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod...</p>
                        <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 p-0">
                <div class="single-blog-item" style="background-image: url(assets/img/blog/blog3.jpg)">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a href="#">Admin</a></li>
                                <li>August 15, 2022</li>
                            </ul>
                        </div>

                        <h3><a href="#">Here are the 5 most telling signs of Micromanagement</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod...</p>
                        <a href="#" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-view-more-btn">
                    <a href="blog-1.html" class="btn btn-primary">View More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Wrap Area -->
