<!-- Start Main Banner Area -->
<!-- <div class="main-banner">
    <div class="container-fluid p-0">
        <div class="row"  style="background-image: url(assets/img/bigdata-analytics/main-banner.jpg);">
           <img src="../assets/img/bigdata-analytics/main-banner.jpg" alt="">
        </div>
        <div class="row m-0">
            <div class="col-lg-7 col-md-12 p-0">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1>Solve Business Challenges With IT</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <div class="btn-box">
                                <a routerLink="/contact" class="btn btn-primary">Read More</a>
                                <a routerLink="/contact" class="btn btn-light">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12 p-0">
                <div class="main-banner-image">
                    <img src="assets/img/banner-img1.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div> 

        <div class="banner-inner-content">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="single-inner-box">
                        <div class="icon">
                            <i class="flaticon-branch"></i>
                        </div>
                        <h3><a routerLink="/service-details">IT Management <i class="flaticon-add-1"></i></a></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="single-inner-box">
                        <div class="icon">
                            <i class="flaticon-programming-code"></i>
                        </div>
                        <h3><a routerLink="/service-details">Development <i class="flaticon-add-1"></i></a></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 offset-lg-0 offset-md-0 offset-sm-3">
                    <div class="single-inner-box">
                        <div class="icon">
                            <i class="flaticon-tools-and-utensils"></i>
                        </div>
                        <h3><a routerLink="/service-details">UX/UI Design <i class="flaticon-add-1"></i></a></h3>
                    </div>
                </div>
            </div>

            <div class="map"><img src="assets/img/circle-map2.png" alt="image"></div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape12.png" alt="image"></div>
</div>  -->
<!-- End Main Banner Area -->

<section>
    <div class="section">
        <h1 class="head fs-3"><b>ACCELERATING BUSINESS OPERATIONS<br>
                WITH INNOVATIVE SOLUTIONS AND SERVICES</b></h1>
        <p class="pre">GTF provides quality services and innovative solutions to support organizational evolution.</p>
    
        <div class="get h-25">
            <a class="btn btn-primary" href="https://profun-ng.envytheme.com/">Get Started</a>
        </div>
    
        <div class="row mt-5">
            <div class="col-lg-4 col-md-6 col-sm-6">
    
                <h3 class="h3 data">
    
                    Analyze Your Data
                    <p class="para">GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>
                </h3>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <h3 class="h3 data">Customized Plan
                    <p class="para">GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>
                </h3>
            </div>
    
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <h3 class="h3 data">Implement Solution
                    <p class="para">GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>
                </h3>
            </div>
        </div>
    </div>
</section>

<!-- Start Services Area -->
<section class="services-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Services</span>
                <!-- <h2>We Offer Professional IT Solutions For Business</h2> -->
                <p>GTF provides quality services and innovative solutions to support organizational evolution.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title1.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-plugin"></i>
                    </div>
                    <h3><a routerLink="/service-details">Enterprise Resource Planning</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-computer-graphic"></i>
                    </div>
                    <h3><a routerLink="/service-details">Business Process Re-engineering</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3><a routerLink="/service-details">Robotic Process Automation</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-code"></i>
                    </div>
                    <h3><a routerLink="/service-details">Center of Excellence for Dev Ops</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-application"></i>
                    </div>
                    <h3><a routerLink="/service-details">Center of Excellence For Quality Assurance</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3><a routerLink="/service-details">Training</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3><a routerLink="/service-details">
                            Reporting</a></h3>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Services Area -->

<!-- Start About Area -->
<section class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>ABOUT OUR COMPANY</span>
                    <!-- <h2>We've Been Thriving in 37 Years The Tech Area</h2> -->
                    <p>GTF is a woman and minority owned small business and SWaM(Small,Woman-Owned, and Minority-Owned
                        Business) is a full-service information technology and management consulting firm, certified ISO
                        9001:2015 for Quality Management Systems, ISO:27001:2013 for Information Security Management
                        Systems.</p>
                    <p>We focus on providing services across financial management and accounting areas which enables
                        government organizations and companies select, implement, modernize, integrate and maintain
                        critical end-to-end business solutions. GTF helps our customers expand financial operations’,
                        increase efficiency, effectiveness, level of accountability, minimize costs, comply with laws, &
                        regulations. Based on our experience within both the Government and Commercial enterprises, GTF
                        understands our client needs and the dedication required to get the job done precisely. We
                        strive to provide world-class solutions to our customers through our own independent research or
                        from our past experience. GTF recognizes the need for improvement therefore we immerse ourselves
                        in our customers’ daily business operations to learn more about environments, issues, and
                        challenges.</p>
                    <a routerLink="/about" class="btn btn-primary">View More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Why choose us</span>
                <h2>We Different From Others Should Choose Us</h2>
                <p>GTF is a woman and minority owned small business and SWaM(Small,Woman-Owned, and Minority-Owned
                    Business) is a full-service information technology and management consulting firm, certified ISO
                    9001:2015 for Quality Management Systems, ISO:27001:2013 for Information Security Management
                    Systems.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title2.png" alt="image">
            </div>
        </div>

        <!-- START EASY TO MANAGE AREA -->
        <!-- <div class="d-flex justify-content-between align-items-center">
            <div class="">
                <h1 class="easy">
                    Business Transformation and Modernization
                </h1>
                <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics Process <br>
                    automation
                    (RPA),Artificial intelligence(AI) & Machine Learning(ML). Our innovative methodology enhances
                    utilization of
                    COTS functionality for automating configurations, standard processes and robust reporting.
                </p>
            </div>
            <div class="">
                <img src="/assets/img/about.jpg" alt="image" class="w-100">
            </div>

        </div> -->
        <div class="row">
            <div class="col-lg-6  col-md-6 mt-5">
                <h1 class="easy">
                    Business Transformation and Modernization
                </h1>
                <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics Process
                    automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative methodology
                    enhances utilization of COTS functionality for automating configurations, standard processes and
                    robust reporting.
                </p>
            </div>
            <div class="col-lg-6 col-md-6">
                <img src="/assets/img/about.jpg" alt="image">
            </div>
        </div>
        <!-- END EASY TO MANAGE AREA -->

        <!-- START BUILT IN AREA -->

        <div class="row">
            <div class="col-lg-6">
                <div>
                    <h1 class="built">
                        Built In Powerful Features You Can't Miss </h1>
                    <p>GTF is a woman and minority owned small business and SWaM(Small,Woman-Owned, and Minority-Owned
                        Business) is a full-service information technology and management consulting firm, certified ISO
                        9001:2015 for Quality Management Systems, ISO:27001:2013 for Information Security Management
                        Systems.
                    </p>
                </div>
                <div class="d-flex justify-content-between mx-5 my-3">
                    <i class="fa-solid fa-screwdriver-wrench wrenchi fs-4"></i>
                    <i class="fa-solid fa-heart wrenchi fs-4"></i>
                    <i class="fa-solid fa-screwdriver-wrench wrenchi fs-4"></i>
                    <i class="fa-solid fa-heart wrenchi fs-4"></i>
                </div>
                <div class="d-flex justify-content-between mx-5 mt-3">
                    <p>2350</p>
                    <p>871</p>
                    <p>1580</p>
                    <p>1358</p>
                </div>
                <div class="d-flex justify-content-between mx-5 ">
                    <p><b>Users</b></p>
                    <p><b>Acounts</b></p>
                    <p><b>Developers</b></p>
                    <p><b>Download</b></p>
                </div>
            </div>
            <div class="col-lg-6 mt-3">
                <div class="ui-card-1 w-75 mb-2 ms-5">
                    <div class="d-flex ms-2">
                        <i class="fa fa-cog mt-3 me-2 fs-4" aria-hidden="true"></i>
                        <p class="mt-3"><b>Simple &
                                Light</b></p>
                    </div>
                    <p class="ms-5"> GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>

                </div><br>
                <div class="ui-card-2 w-75 mb-2 ms-5">
                    <div class="d-flex ms-2">
                        <i class="fa fa-cog mt-3 me-2 fs-4" aria-hidden="true"></i>
                        <p class="mt-3"><b>Creative Design</b></p>
                    </div>
                    <p class="ms-5"> GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>

                </div><br>
                <div class="ui-card-3 w-75 mb-2 ms-5">
                    <div class="d-flex ms-2">
                        <i class="fa fa-cog mt-3 me-2 fs-4" aria-hidden="true"></i>
                        <p class="mt-3"><b>Retina Ready</b></p>
                    </div>
                    <p class="ms-5"> GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>

                </div><br>
                <div class="ui-card-4 w-75 mb-2 ms-5">
                    <div class="d-flex ms-2">
                        <i class="fa fa-cog mt-3 me-2 fs-4" aria-hidden="true"></i>
                        <p class="mt-3"><b>Unlimited Features</b></p>
                    </div>
                    <p class="ms-5"> GTF provides quality services and innovative solutions to support organizational
                        evolution.</p>

                </div>
            </div>
        </div>



        <!-- END BUILT IN AREA -->


        <div class="row mt-5">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-choose-box">
                    <img src="assets/img/why-choose-us/why-choose-us1.jpg" alt="image">

                    <div class="title">
                        <h3>Enterprise Resource Planning</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3><a routerLink="/service-details">Enterprise Resource Planning</a></h3>
                            <!-- <p>Lorem ipsum dolor amet, adipiscing elit, sed do eiusmod tempor ut labore et dolore magna
                                aliqua. Quis ipsum suspendisse ultrices gravida.</p> -->
                            <a routerLink="/service-details" class="read-more-btn">Read More <i
                                    class="flaticon-add-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-choose-box">
                    <img src="assets/img/why-choose-us/why-choose-us2.jpg" alt="image">

                    <div class="title">
                        <h3>Business Process Re-engineering</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3><a routerLink="/service-details">Business Process Re-engineering</a></h3>
                            <!-- <p>Lorem ipsum dolor amet, adipiscing elit, sed do eiusmod tempor ut labore et dolore magna
                                aliqua. Quis ipsum suspendisse ultrices gravida.</p> -->
                            <a routerLink="/service-details" class="read-more-btn">Read More <i
                                    class="flaticon-add-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-choose-box">
                    <img src="assets/img/why-choose-us/why-choose-us3.jpg" alt="image">

                    <div class="title">
                        <h3>Robotic Process Automation</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3><a routerLink="/service-details">Robotic Process Automation</a></h3>
                            <!-- <p>Lorem ipsum dolor amet, adipiscing elit, sed do eiusmod tempor ut labore et dolore magna
                                aliqua. Quis ipsum suspendisse ultrices gravida.</p> -->
                            <a routerLink="/service-details" class="read-more-btn">Read More <i
                                    class="flaticon-add-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Why Choose Us Area -->

<!-- Start FunFacts Area -->
<section class="funfacts-area ptb-110 bg-f2feee">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="60"></div>
                    <span>Design</span>
                    <h3>Codes</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="75"></div>
                    <span>Our happy</span>
                    <h3>Clients</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="85"></div>
                    <span>Projects</span>
                    <h3>Design</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4 offset-lg-0 offset-md-0 offset-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="95"></div>
                    <span>Completed</span>
                    <h3>Projects</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts Area -->

<!-- Start CTA Area -->
<section class="cta-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <span>We Take Care of Your Technology</span>
            <h2>Focus on Your Business</h2>
            <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
        </div>
    </div>
</section>
<!-- End CTA Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Recent projects</span>
                <h2>Proud Projects That Make Us Stand Out</h2>
                <p>GTF Enterprise Resource Planning (ERP) Center of Excellence (COE) establishes an integrated
                    process-driven
                    methodology designed for successful implementations, upgrades and maintenance. Our proven
                    practices provides our
                    clients with improved business process efficiencies and enforces standardization by minimizing
                    customizations to
                    reduce implementation and operational costs. Our ERP practice contains cross-functional teams of
                    subject matter
                    experts with business processes, technical developers, solution architects, quality management
                    resources, change
                    management and training teams with experience supporting Federal, State & Local and Commercial
                    clients. Our ERP
                    capabilities include Oracle Federal Financials, Oracle Financials, PRISM, PeopleSoft and
                    Hyperion applications
                    support.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title3.png" alt="image">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects1.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design/Idea</span>
                        <h3><a routerLink="/project-details">Business Process Re-engineering</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                            viverra maecenas.</p> -->
                        <a routerLink="/project-details" class="read-more-btn">Read More <i
                                class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects2.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design/Development</span>
                        <h3><a routerLink="/project-details">Robotic Process Automation</a></h3>

                        <a routerLink="/project-details" class="read-more-btn">Read More <i
                                class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects3.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design</span>
                        <h3><a routerLink="/project-details">Enterprise Resource Planning</a></h3>

                        <a routerLink="/project-details" class="read-more-btn">Read More <i
                                class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects4.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Idea</span>
                        <h3><a routerLink="/project-details">Center of Excellence for DevOps</a></h3>

                        <a routerLink="/project-details" class="read-more-btn">Read More <i
                                class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects5.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Guard</span>
                        <h3><a routerLink="/project-details">Center of Excellence For Quality Assurance</a></h3>

                        <a routerLink="/project-details" class="read-more-btn">Read More <i
                                class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Projects Area -->

<!-- Start Partner Area -->
<div class="partner-area bg-f2feee">
    <div class="container">
        <div class="partner-list">
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="image"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Team Area -->
<section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Experts</span>
                <h2>Meet Our Leadership Preparing For Your Success</h2>
                <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics Process
                    automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative methodology
                    enhances utilization of COTS functionality for automating configurations, standard processes and
                    robust reporting.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title4.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Joe Root</h3>
                        <span>Lead Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Daisy Lucy</h3>
                        <span>Lead Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start Testimonials Area -->
<section class="testimonials-area">
    <div class="container-fluid p-0">
        <div class="testimonials-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="testimonials-inner-content">
                <div class="testimonials-slides owl-carousel owl-theme">
                    <div class="single-testimonials-item">
                        <p>GTF is a distinguished, dynamic and value driven business is based on 3 I’s: innovation,
                            information and intelligence. Our forward thinking enables clients to stay ahead of the
                            competition.
                        </p>

                        <div class="client-info">
                            <img src="assets/img/author1.jpg" alt="image">
                            <h3>John Doe</h3>
                            <span>Marketing</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics
                            Process automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative
                            methodology enhances utilization of COTS functionality for automating configurations,
                            standard processes and robust reporting.
                        </p>

                        <div class="client-info">
                            <img src="assets/img/author2.jpg" alt="image">
                            <h3>Steven Smith</h3>
                            <span>Marketing</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics
                            Process automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative
                            methodology enhances utilization of COTS functionality for automating configurations,
                            standard processes and robust reporting.
                        </p>

                        <div class="client-info">
                            <img src="assets/img/author3.jpg" alt="image">
                            <h3>Sarah Taylor</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>

                <div class="circle-map"><img src="assets/img/circle-map3.png" alt="image"></div>
            </div>

            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                    class="flaticon-play-button"></i></a>
        </div>
    </div>
</section>
<!-- End Testimonials Area -->

<!-- Start Blog Area -->
<section class="blog-area extra-pb ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Latest News</span>
                <h2>Our Latest Insights Are On Top All Times</h2>
                <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics Process
                    automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative methodology
                    enhances utilization of COTS functionality for automating configurations, standard processes and
                    robust reporting.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title5.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post sticky">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 16, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">10 Building Mobile Apps With Ionic And React</a></h3>
                    </div>
                </div>

                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>GTF provides quality services and innovative solutions to support organizational evolution.
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 18, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <p>GTF provides quality services and innovative solutions to support organizational evolution.
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>

                <div class="single-blog-post link">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 01, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">The hardest leadership advice to follow</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post quote">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 08, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">How to share your company vision as a leader</a></h3>
                    </div>
                </div>

                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Here are the 5 most telling signs of micromanagement</a></h3>
                        <p>GTF provides quality services and innovative solutions to support organizational evolution.
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Blog Area -->
