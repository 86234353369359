<div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Software Prototyping</h2>
        <ul>
          <li><a routerLink="/services">Services</a></li>
          <li>Software Prototyping</li>
        </ul>
      </div>
    </div>
  </div>