<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Experts</span>
                <h2>Meet Our Leadership Preparing For Your Success</h2>
                <p>GTF integrates business reengineering with digital transformation utilizing RPA Robotics Process
                    automation (RPA), Artificial intelligence(AI) & Machine Learning(ML). Our innovative methodology
                    enhances utilization of COTS functionality for automating configurations, standard processes and
                    robust reporting.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title4.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Joe Root</h3>
                        <span>Lead Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Daisy Lucy</h3>
                        <span>Lead Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team5.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Rowdy Jong</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team6.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Sofia Layla</h3>
                        <span>Marketing Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team7.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Jimmy Warner</h3>
                        <span>Lead Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team8.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Emma Daisy</h3>
                        <span>Lead Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Team Area -->
