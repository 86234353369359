// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { HomeComponent } from './components/pages/home/home.component';
// import { AboutComponent } from './components/pages/about/about.component';
// import { TeamComponent } from './components/pages/team/team.component';
// import { ServicesComponent } from './components/pages/services/services.component';
// import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
// import { ProjectsComponent } from './components/pages/projects/projects.component';
// import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
// import { NotFoundComponent } from './components/pages/not-found/not-found.component';
// import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
// import { FaqComponent } from './components/pages/faq/faq.component';
// import { BlogComponent } from './components/pages/blog/blog.component';
// import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
// import { ContactComponent } from './components/pages/contact/contact.component';
// import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
// import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
// import { HomeFourComponent } from './components/pages/home-four/home-four.component';

// const routes: Routes = [
//     { path: '', component: HomeComponent },
//     { path: 'index-2', component: HomeTwoComponent },
//     { path: 'index-3', component: HomeThreeComponent },
//     { path: 'index-4', component: HomeFourComponent },
//     { path: 'about', component: AboutComponent },
//     { path: 'team', component: TeamComponent },
//     { path: 'services', component: ServicesComponent },
//     { path: 'service-details', component: ServiceDetailsComponent },
//     { path: 'projects', component: ProjectsComponent },
//     { path: 'project-details', component: ProjectDetailsComponent },
//     { path: 'coming-soon', component: ComingSoonComponent },
//     { path: 'faq', component: FaqComponent },
//     { path: 'blog', component: BlogComponent },
//     { path: 'blog-details', component: BlogDetailsComponent },
//     { path: 'contact', component: ContactComponent },
//     // Here add new pages component

//     { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { OracleCloudeApplicationComponent } from './services/Oracle Cloud Applications/ERP-Solutions/oracle-cloude-application.component';
import { OracleCloudERPComponent } from './services/Oracle Cloud Applications/financial-system/oracle-cloud-erp.component';
import { OracleHCMCloudComponent } from './services/Oracle Cloud Applications/acquistions-procurement-systems/oracle-hcm-cloud.component';
import { OracleEpmCloudComponent } from './services/Oracle Cloud Applications/asset-management-systems/oracle-epm-cloud.component';
import { GrantsExecutionComponent } from './services/Federal Grants/grants-execution/grants-execution.component';
import { GrantsCloseOutComponent } from './services/Federal Grants/grants-close-out/grants-close-out.component';
import { GrantsAccountingComponent } from './services/Federal Grants/grants-accounting/grants-accounting.component';
import { AuditSupportComponent } from './services/Federal Grants/audit-support/audit-support.component';
import { HelpdeskSupportComponent } from './services/ITSM/helpdesk-support/helpdesk-support.component';
import { ReleaseManagementComponent } from './services/ITSM/release-management/release-management.component';
import { CICDComponent } from './services/ITSM/ci-cd/ci-cd.component';
import { CloudBasedSoftwareDevelopmentComponent } from './services/Softwer Development/cloud-based-software-development/cloud-based-software-development.component';
import { CustomSoftwareDevelopmentComponent } from './services/Softwer Development/custom-software-development/custom-software-development.component';
import { EnterpriseSoftwareDevelopmentComponent } from './services/Softwer Development/enterprise-software-development/enterprise-software-development.component';
import { SoftwareIntegrationComponent } from './services/Softwer Development/software-integration/software-integration.component';
import { SoftwareMaintenanceUpgradesComponent } from './services/Softwer Development/software-maintenance-upgrades/software-maintenance-upgrades.component';
import { SoftwarePrototypingComponent } from './services/Softwer Development/software-prototyping/software-prototyping.component';
import { ArtificialIntellegenceComponent } from './services/Digital Tranformation/artificial-intellegence/artificial-intellegence.component';
import { MachineLearningComponent } from './services/Digital Tranformation/machine-learning/machine-learning.component';
import { RoboticsProcessAutomationComponent } from './services/Digital Tranformation/robotics-process-automation/robotics-process-automation.component';
import { CommunicationManagementComponent } from './services/Orginazation Change/communication-management/communication-management.component';
import { TrainingComponent } from './services/Orginazation Change/training/training.component';
import { ContentManagementComponent } from './services/Orginazation Change/content-management/content-management.component';
import { EOLComponent } from './services/Security & Vulnerability/eol/eol.component';
import { CloudComponent } from './services/Infrastructure  Management/cloud/cloud.component';
import { FiscamComponent } from './services/Infrastructure  Management/fiscam/fiscam.component';
import { OnPremComponent } from './services/Infrastructure  Management/on-prem/on-prem.component';
import { WaterfallComponent } from './services/Program Management/waterfall/waterfall.component';
import { AgileComponent } from './services/Program Management/agile/agile.component';

const routes: Routes = [
    { path: '', component: HomeThreeComponent },
    { path: 'index-2', component: HomeTwoComponent },
    { path: 'index-3', component: HomeThreeComponent },
    { path: 'index-4', component: HomeFourComponent },
    { path: 'about', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'service-details', component: ServiceDetailsComponent },
    { path: 'projects', component: ProjectsComponent },
    { path: 'project-details', component: ProjectDetailsComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    // Add new services component here
    { path: 'oracle-cloude-application', component: OracleCloudeApplicationComponent },
    { path: 'oracle-cloud-erp', component: OracleCloudERPComponent },
    { path: 'oracle-hcm-cloud', component: OracleHCMCloudComponent },
    { path: 'oracle-epm-cloud', component: OracleEpmCloudComponent },

    { path: 'grant-execution', component: GrantsExecutionComponent },
    { path: 'grant-close-out', component: GrantsCloseOutComponent },
    { path: 'grant-accounting', component: GrantsAccountingComponent },
    { path: 'audit-support', component: AuditSupportComponent },

    { path: 'helpdesk', component: HelpdeskSupportComponent },
    { path: 'release', component: ReleaseManagementComponent },
    { path: 'cicd', component: CICDComponent },

    { path: 'cloud-based', component: CloudBasedSoftwareDevelopmentComponent },
    { path: 'custom-software', component: CustomSoftwareDevelopmentComponent },
    { path: 'enterprise-software', component: EnterpriseSoftwareDevelopmentComponent },
    { path: 'software-integration', component: SoftwareIntegrationComponent },
    { path: 'software-maintenace', component: SoftwareMaintenanceUpgradesComponent },
    { path: 'software-prototyping', component: SoftwarePrototypingComponent },

    { path: 'artificial', component: ArtificialIntellegenceComponent },
    { path: 'machine-learning', component: MachineLearningComponent },
    { path: 'robotics', component: RoboticsProcessAutomationComponent },

    { path: 'communication-management', component: CommunicationManagementComponent },
    { path: 'training', component: TrainingComponent },
    { path: 'content-management', component: ContentManagementComponent },

    { path: 'eol', component: EOLComponent },

    { path: 'cloud', component: CloudComponent },
    { path: 'fiscam', component: FiscamComponent },
    { path: 'on-prem', component: OnPremComponent },

    { path: 'waterfall', component: WaterfallComponent },
    { path: 'agile', component: AgileComponent },


    // Here add new pages component

    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }