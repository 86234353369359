<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Grants Execution</h2>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><a routerLink="/federal-grant">Federal Grants Management & Grants Management</a></li>
                <li>Grants Execution</li>
            </ul>
        </div>
    </div>
</div>

<!-- <div class="contanier-fuild">
    <img src="../../../../../assets/img/2148525276.jpg" width="100%" alt="">
</div>   -->

<section class="grant-execution">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 grant-execution-text">
          <h2>Grants Execution</h2>
          <p>
            During the active phase, we assist in implementing the terms of your grant and ensuring compliance with all conditions. Our expertise translates government mandates into efficient workflows, encompassing both manual and automated processes. We are dedicated to providing meticulously detailed service, outstanding work quality, and comprehensive solutions for every aspect of your grant. From start to finish, GTF stands ready to help your organization excel in grant management, ultimately leading to more successful grant applications in the future.
          </p>
          <ul class="list-unstyled grant-benefits">
            <li>
              <i class="fas fa-check-circle"></i> Meticulous and Detailed Service
            </li>
            <li>
              <i class="fas fa-star"></i> Outstanding Work Quality
            </li>
            <li>
              <i class="fas fa-layer-group"></i> Comprehensive Grant Management Solutions
            </li>
          </ul>
          <a href="#" class="btn btn-primary">Learn More About Our Services</a>
        </div>
        <div class="col-md-6 grant-execution-image">
          <img src="../../../../assets/img/2148525276.jpg" alt="Grant Execution Image">
        </div>
      </div>
    </div>
  </section>