import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grants-close-out',
  templateUrl: './grants-close-out.component.html',
  styleUrls: ['./grants-close-out.component.scss']
})
export class GrantsCloseOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
