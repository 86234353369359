<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Grants Accounting</h2>
            <ul>
                <li><a routerLink="/services">Services</a></li>
                <li><a routerLink="/federal-grant">Federal Grants Management & Grants Management</a></li>
                <li>Grants Accounting</li>
            </ul>
        </div>
    </div>
</div>


<section class="grant-execution">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 grant-execution-text">
          <h2>Grants Accounting</h2>
          <p>
            Throughout the lifecycle of a grant, accounting for the expenditure of grant funds is a critically important task. Grant conditions are set by a variety of stakeholders – the Treasury Department being one of the most crucially important to comply with as a matter of law. GTF provides deep understanding and expertise in developing process improvements to ensure that accounting for the grant transactions is compliant with Treasury financial mandates and any other conditions. Our experienced resources can assist your organization with navigating this crucial requirement of Federal Grants Management.
          </p>
          <ul class="list-unstyled grant-benefits">
            <li>
              <i class="fas fa-check-circle"></i> Meticulous and Detailed Service
            </li>
            <li>
              <i class="fas fa-star"></i> Outstanding Work Quality
            </li>
            <li>
              <i class="fas fa-layer-group"></i> Comprehensive Grant Management Solutions
            </li>
          </ul>
          <a href="#" class="btn btn-primary">Learn More About Our Services</a>
        </div>
        <div class="col-md-6 grant-execution-image">
          <img src="../../../../assets/img/2148525276.jpg" alt="Grant Execution Image">
        </div>
      </div>
    </div>
  </section>