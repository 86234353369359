import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oracle-epm-cloud',
  templateUrl: './oracle-epm-cloud.component.html',
  styleUrls: ['./oracle-epm-cloud.component.scss']
})
export class OracleEpmCloudComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
