<div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Traning</h2>
        <ul>
          <li><a routerLink="/services">Services</a></li>
          <li>Traning</li>
        </ul>
      </div>
    </div>
  </div>