// import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';

// import { AppRoutingModule } from './app-routing.module';
// import { AppComponent } from './app.component';
// import { PreloaderComponent } from './components/layout/preloader/preloader.component';
// import { HeaderComponent } from './components/layout/header/header.component';
// import { FooterComponent } from './components/layout/footer/footer.component';
// import { HomeComponent } from './components/pages/home/home.component';
// import { AboutComponent } from './components/pages/about/about.component';
// import { TeamComponent } from './components/pages/team/team.component';
// import { ServicesComponent } from './components/pages/services/services.component';
// import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
// import { NotFoundComponent } from './components/pages/not-found/not-found.component';
// import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
// import { FaqComponent } from './components/pages/faq/faq.component';
// import { BlogComponent } from './components/pages/blog/blog.component';
// import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
// import { ProjectsComponent } from './components/pages/projects/projects.component';
// import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
// import { ContactComponent } from './components/pages/contact/contact.component';
// import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
// import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
// import { HomeFourComponent } from './components/pages/home-four/home-four.component';

// @NgModule({
//   declarations: [
//     AppComponent ,
//     PreloaderComponent,
//     HeaderComponent,
//     FooterComponent,
//     HomeComponent,
//     AboutComponent,
//     TeamComponent,
//     ServicesComponent,
//     ServiceDetailsComponent,
//     NotFoundComponent,
//     ComingSoonComponent,
//     FaqComponent,
//     BlogComponent,
//     BlogDetailsComponent,
//     ProjectsComponent,
//     ProjectDetailsComponent,
//     ContactComponent,
//     HomeTwoComponent,
//     HomeThreeComponent,
//     HomeFourComponent
//   ],
//   imports: [
//     BrowserModule,
//     AppRoutingModule
    
//   ],
//   providers: [],
//   bootstrap: [AppComponent]
// })
// export class AppModule { }


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layout/preloader/preloader.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { RouterModule } from '@angular/router';
import { OracleCloudeApplicationComponent } from './services/Oracle Cloud Applications/ERP-Solutions/oracle-cloude-application.component';
import { OracleHCMCloudComponent } from './services/Oracle Cloud Applications/acquistions-procurement-systems/oracle-hcm-cloud.component';
import { OracleCloudERPComponent } from './services/Oracle Cloud Applications/financial-system/oracle-cloud-erp.component';
import { OracleEpmCloudComponent } from './services/Oracle Cloud Applications/asset-management-systems/oracle-epm-cloud.component';
import { GrantsExecutionComponent } from './services/Federal Grants/grants-execution/grants-execution.component';
import { GrantsAccountingComponent } from './services/Federal Grants/grants-accounting/grants-accounting.component';
import { GrantsCloseOutComponent } from './services/Federal Grants/grants-close-out/grants-close-out.component';
import { AuditSupportComponent } from './services/Federal Grants/audit-support/audit-support.component';
import { HelpdeskSupportComponent } from './services/ITSM/helpdesk-support/helpdesk-support.component';
import { ReleaseManagementComponent } from './services/ITSM/release-management/release-management.component';
import { CICDComponent } from './services/ITSM/ci-cd/ci-cd.component';
import { RoboticsProcessAutomationComponent } from './services/Digital Tranformation/robotics-process-automation/robotics-process-automation.component';
import { ArtificialIntellegenceComponent } from './services/Digital Tranformation/artificial-intellegence/artificial-intellegence.component';
import { MachineLearningComponent } from './services/Digital Tranformation/machine-learning/machine-learning.component';
import { CustomSoftwareDevelopmentComponent } from './services/Softwer Development/custom-software-development/custom-software-development.component';
import { EnterpriseSoftwareDevelopmentComponent } from './services/Softwer Development/enterprise-software-development/enterprise-software-development.component';
import { CloudBasedSoftwareDevelopmentComponent } from './services/Softwer Development/cloud-based-software-development/cloud-based-software-development.component';
import { SoftwarePrototypingComponent } from './services/Softwer Development/software-prototyping/software-prototyping.component';
import { SoftwareIntegrationComponent } from './services/Softwer Development/software-integration/software-integration.component';
import { SoftwareMaintenanceUpgradesComponent } from './services/Softwer Development/software-maintenance-upgrades/software-maintenance-upgrades.component';
import { CommunicationManagementComponent } from './services/Orginazation Change/communication-management/communication-management.component';
import { TrainingComponent } from './services/Orginazation Change/training/training.component';
import { ContentManagementComponent } from './services/Orginazation Change/content-management/content-management.component';
import { EOLComponent } from './services/Security & Vulnerability/eol/eol.component';
import { OnPremComponent } from './services/Infrastructure  Management/on-prem/on-prem.component';
import { CloudComponent } from './services/Infrastructure  Management/cloud/cloud.component';
import { FiscamComponent } from './services/Infrastructure  Management/fiscam/fiscam.component';
import { WaterfallComponent } from './services/Program Management/waterfall/waterfall.component';
import { AgileComponent } from './services/Program Management/agile/agile.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    TeamComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    NotFoundComponent,
    ComingSoonComponent,
    FaqComponent,
    BlogComponent,
    BlogDetailsComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    ContactComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    OracleCloudeApplicationComponent,
    OracleHCMCloudComponent,
    OracleCloudERPComponent,
    GrantsExecutionComponent,
    GrantsAccountingComponent,
    GrantsCloseOutComponent,
    AuditSupportComponent,
    HelpdeskSupportComponent,
    ReleaseManagementComponent,
    CICDComponent,
    RoboticsProcessAutomationComponent,
    ArtificialIntellegenceComponent,
    MachineLearningComponent,
    CustomSoftwareDevelopmentComponent,
    EnterpriseSoftwareDevelopmentComponent,
    CloudBasedSoftwareDevelopmentComponent,
    SoftwarePrototypingComponent,
    SoftwareIntegrationComponent,
    SoftwareMaintenanceUpgradesComponent,
    CommunicationManagementComponent,
    TrainingComponent,
    ContentManagementComponent,
    EOLComponent,
    OnPremComponent,
    CloudComponent,
    FiscamComponent,
    WaterfallComponent,
    AgileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
   
  ],
 
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
